import React, { FC, useState, useEffect } from "react";
import {
  Container,
  Navbar,
  ListGroup,
  NavDropdown,
  Nav,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import Logo from "components/common/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faFileImport,
  faBookOpen,
  faLink,
  faQuestionCircle,
  faCopy,
  faGear,
  faCalculator,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Link from "next/link";

import { logout } from "lib/firebase";
import User, { UserRepository } from "domains/User";

type Props = {
  children?: React.ReactNode;
};

const BaseLayout: FC<Props> = ({ children }) => {
  const [me, setMe] = useState<User | null>(null);

  const fetchMe = async () => {
    const res = await UserRepository.me();
    setMe(res.item);
  };

  useEffect(() => {
    fetchMe();
  }, []);

  const copyCode = () => {
    if (!me || !me.company) return;

    navigator.clipboard.writeText(me?.company?.code);
    toast.success("コピーしました");
  };

  const isActive = (path: string) => {
    const activeClass = "bg-primary active";
    const inActiveClass = "bg-dark";
    let res = false;
    res = location.pathname.startsWith(path);
    if (!res && path === "/emissions/") {
      res = location.pathname === "/";
    }
    return res ? activeClass : inActiveClass;
  };

  const menuItemClass = "w-100";
  const iconClass = "sidebar-item-icon mx-2";
  const textClass = "text-white";

  const menu = (
    <ListGroup className="sidebar align-items-end h-100 pb-2" variant="flush">
      <Link passHref href="/tools/">
        <ListGroup.Item
          action
          className={`${menuItemClass} ${isActive("/tools/")}`}
        >
          <FontAwesomeIcon icon={faCalculator} className={`${iconClass}`} />
          <small className={`${textClass}`} role="button">
            排出量算出ツール
            <Badge bg="danger" className="ms-2">
              New!
            </Badge>
          </small>
        </ListGroup.Item>
      </Link>
      <Link passHref href="/">
        <ListGroup.Item
          action
          className={`${menuItemClass} ${isActive("/emissions/")}`}
        >
          <FontAwesomeIcon icon={faPaperPlane} className={iconClass} />
          <small className={textClass} role="button">
            レコード送信
          </small>
        </ListGroup.Item>
      </Link>
      <Link passHref href="/receives/">
        <ListGroup.Item
          action
          className={`${menuItemClass} ${isActive("/receives/")}`}
        >
          <FontAwesomeIcon icon={faFileImport} className={iconClass} />
          <small className={textClass} role="button">
            レコード受信
          </small>
        </ListGroup.Item>
      </Link>
      <Link passHref href="/activities/">
        <ListGroup.Item
          action
          className={`${menuItemClass} ${isActive("/activities/")}`}
        >
          <FontAwesomeIcon icon={faBookOpen} className={iconClass} />
          <small className={textClass} role="button">
            活動内容登録
          </small>
        </ListGroup.Item>
      </Link>
      <Link passHref href="/companies/">
        <ListGroup.Item
          action
          className={`${menuItemClass} ${isActive("/companies/")}`}
        >
          <FontAwesomeIcon icon={faLink} className={iconClass} />
          <small className={textClass} role="button">
            リンク設定
          </small>
        </ListGroup.Item>
      </Link>
      <Link passHref href="/contact/">
        <a target="_blank" rel="nofollow noopener noreferrer" className="w-100">
          <ListGroup.Item
            action
            className={`${menuItemClass} ${isActive("/contact/")}`}
          >
            <FontAwesomeIcon icon={faEnvelopeOpen} className={iconClass} />
            <small className={textClass} role="button">
              お問い合わせ
            </small>
          </ListGroup.Item>
        </a>
      </Link>
      <ListGroup.Item className="bg-dark mt-auto w-100 d-none d-sm-block">
        <footer className="footer">
          <a
            className="text-muted small vitaly-asia"
            href="https://vitaly.asia/"
            target="_blank"
            rel="noopener noreferrer"
          >
            2022, Vitaly Asia Pte Ltd.
          </a>
        </footer>
      </ListGroup.Item>
    </ListGroup>
  );

  return (
    <div id="layout" className="bg-light">
      <Navbar bg="white" expand="lg" className="border-bottom px-3">
        <Navbar.Brand>
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Link href="https://carbonlinks-guide.mystrikingly.com/" passHref>
            <a target="_blank" rel="nofollow noopener noreferrer">
              <Nav
                className="ms-auto me-3 py-3 py-md-0 text-center"
                style={{ cursor: "help" }}
              >
                <span className="small text-info">
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="h6 mb-0 me-1"
                  />
                  マニュアル
                </span>
              </Nav>
            </a>
          </Link>
          {me && me.company && (
            <Nav className="me-4 py-3 py-md-0 text-center">
              <span className="small text-muted" onClick={copyCode}>
                企業コード: {me?.company?.code}{" "}
                <FontAwesomeIcon icon={faCopy} className="h6 mb-0" />
              </span>
            </Nav>
          )}
          <NavDropdown
            id="header-dropdown"
            title={
              <p id="header-company-name" className="mb-0">
                <FontAwesomeIcon icon={faGear} className="text-dark h6 mb-0" />
                <span className="ms-1 text-muted h6 py-4 py-md-0">
                  {me?.company?.name || "設定"}
                </span>
              </p>
            }
            className="after-none text-end py-3 py-md-0"
          >
            <Link href="/settings/me/" passHref>
              <NavDropdown.Item>アカウント情報</NavDropdown.Item>
            </Link>
            <Link href="/settings/company/" passHref>
              <NavDropdown.Item>企業情報</NavDropdown.Item>
            </Link>
            <NavDropdown.Divider />
            {/* <NavDropdown.Item href="/settings/help">ヘルプ</NavDropdown.Item> */}
            <NavDropdown.Item onClick={() => logout()}>
              ログアウト
            </NavDropdown.Item>
          </NavDropdown>
          <section className="d-block d-sm-none">{menu}</section>
        </Navbar.Collapse>
      </Navbar>

      <Row className="g-0">
        <Col sm={3}>
          <section className="sidebar-wrap d-none d-sm-block bg-dark">
            {menu}
          </section>
        </Col>
        <Col sm={9}>
          <main className="main w-100">
            <Container>{children}</Container>
          </main>
        </Col>
      </Row>

      <footer className="sp-footer position-absolute d-block d-sm-none">
        <p className="mb-0 text-center">
          <a
            className="text-muted small vitaly-asia"
            href="https://vitaly.asia/"
            target="_blank"
            rel="noopener noreferrer"
          >
            2022, Vitaly Asia Pte Ltd.
          </a>
        </p>
      </footer>
    </div>
  );
};

export default BaseLayout;
