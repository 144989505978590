import React, { FC } from "react";
import Link from "next/link";
import EnvBadge from "components/common/EnvBadge";
import { Image } from "react-bootstrap";

type Props = {
  large?: boolean;
};

const Logo: FC<Props> = ({ large }) => {
  const wrapClassName = large ? "d-flex flex-column align-items-center" : "";
  const icon = {
    width: large ? 240 : 60,
    height: large ? 152 : 38,
    className: "me-1",
  };
  const text = {
    width: large ? 274 : 137,
    height: large ? 52 : 26,
    className: "my-2",
  };

  return (
    <>
      <Link href="/" passHref>
        <a className={wrapClassName}>
          <Image
            src="/imgs/logo-i.svg"
            alt="CarbonLinks"
            fluid
            width={icon.width}
            height={icon.height}
            className={icon.className}
          />
          <Image
            src="/imgs/logo-t.svg"
            alt="CarbonLinks"
            fluid
            width={text.width}
            height={text.height}
            className={text.className}
          />
          <EnvBadge />
        </a>
      </Link>
    </>
  );
};

export default Logo;
