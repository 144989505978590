export default class MyError {
  constructor(
    public error?: {
      status: number;
      message: string;
      name: string;
    }
  ) {}
}

export const err = (
  message = "不明なエラーです",
  status = 400,
  name = "エラー"
): MyError => {
  return { error: { status, message, name } };
};
