import React, { FC } from "react";
import Head from "next/head";

type Props = {
  title?: string;
  description?: string;
};

const Meta: FC<Props> = ({ title, description }) => {
  const defaultTitle = "CarbonLinks";
  const defaultDesc =
    "CarbonLinksは完全無料で炭素排出量算出を支援するクラウド型アプリケーションです。";

  const t = title ? `CLS|${title}` : defaultTitle;
  const d = description || defaultDesc;
  const host = process.env.HOST || "https://app.carbonlinks.io";

  return (
    <Head>
      <title>{t}</title>
      <meta property="description" content={d} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={d} />
      <meta property="og:image" content={`${host}/ogp_large.png`} />
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  );
};

export default Meta;
