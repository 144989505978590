import dayjs from "dayjs";
import MyError, { err } from "domains/MyError";
import Activity from "domains/Activity";
import Company from "domains/Company";
import { functions, functionsPayload, getFbUserToken } from "lib/firebase";
import { FbDate, Pages } from "domains/common";
import { commas } from "utils/Helper";
import { httpsCallable } from "firebase/functions";
import User from "./User";
import axios from "axios";
const callable = httpsCallable(functions, "emissions");

export type EmissionStatus = 0 | 1 | 2;
export const CompanyLinkStatusOptions: Record<EmissionStatus, string> = {
  0: "下書き",
  1: "通知",
  2: "確認済",
};
type EmissionResponse = {
  id: string;
  status: EmissionStatus;
  userID: string;
  companyID: string;
  receiverCompanyID: string;
  approveUserID: string;
  note: string;
  title: string;
  manageNo: string;
  transactionedAt: string;
  newCompanyName: string;
  newCompanyEmail: string;
  createdAt: FbDate;
  updatedAt: FbDate;
  archiveSender: boolean;
  archiveReceiver: boolean;
  activities?: Activity[];
  to?: Company;
  activity?: Activity;
  notifiedAt?: FbDate;
  user?: User;
  approveUser?: User;
  company?: Company;
  receiverCompany?: Company;
};
type EmissionResponses = {
  items: EmissionResponse[];
  pages: Pages;
};

type NotificationTargetResponse = {
  id: string;
  name: string;
  category: "company" | "emission";
};

type NotificationTargetResponses = { items: NotificationTargetResponse[] };

const statusJa = (status: number, receive?: boolean) => {
  if (receive) {
    if (status === 1) return "未確認";
    if (status === 2) return "確認済";

    return "-";
  }
  if (status === 1) return "通知済み";
  if (status === 2) return "受信者確認済";

  return "下書き";
};

export default class Emission {
  constructor(
    public id: string,
    public status: number,
    public userID: string,
    public companyID: string,
    public receiverCompanyID: string,
    public approveUserID: string,
    public note: string,
    public title: string,
    public manageNo: string,
    public transactionedAt: string,
    public newCompanyName: string,
    public newCompanyEmail: string,
    public createdAt: FbDate,
    public updatedAt: FbDate,
    public archiveSender: boolean,
    public archiveReceiver: boolean,
    public activities?: Activity[],
    public to?: Company,
    public activity?: Activity,
    public notifiedAt?: FbDate,
    public user?: User,
    public approveUser?: User,
    public company?: Company,
    public receiverCompany?: Company
  ) {}
  public transactionedAtFormatDate(): string {
    if (!this.transactionedAt) {
      return "";
    }
    return dayjs(this.transactionedAt).format("YYYY/MM/DD");
  }
  public createdAtFormatDate(): string {
    if (!this.createdAt) {
      return "";
    }
    return dayjs.unix(this.createdAt._seconds).format("YYYY/MM/DD");
  }
  public notifiedAtFormatDate(): string {
    if (!this.notifiedAt) {
      return "";
    }
    return dayjs.unix(this.notifiedAt._seconds).format("YYYY/MM/DD");
  }
  public async calcEmissionVolume(): Promise<string> {
    if (!this.activities || !this.activities.length) {
      return "";
    }

    const results: number[] = await Promise.all(
      this.activities.map(async (act) => {
        const { volume, intensity } = act;
        return Number(volume) * Number(intensity);
      })
    );
    const sum = results.reduce((a, b) => a + b);
    return `${commas(sum.toFixed(1))} t`;
  }
  public waiting(): boolean {
    return !this.status || this.status === 0;
  }
  public approve(): boolean {
    return this.status === 1;
  }
  public statusJa(): string {
    return statusJa(this.status);
  }
  public statusReceiveJa(): string {
    return statusJa(this.status, true);
  }
}

export class EmissionFactory {
  static createFromResponseObject(res: EmissionResponse): Emission {
    return new Emission(
      res.id,
      res.status,
      res.userID,
      res.companyID,
      res.receiverCompanyID,
      res.approveUserID,
      res.note,
      res.title,
      res.manageNo,
      res.transactionedAt,
      res.newCompanyName,
      res.newCompanyEmail,
      res.createdAt,
      res.updatedAt,
      res.archiveSender,
      res.archiveReceiver,
      res.activities,
      res.to,
      res.activity,
      res.notifiedAt,
      res.user,
      res.approveUser,
      res.company,
      res.receiverCompany
    );
  }
}

export const senderFilter = (emissions: Emission[]): Emission[] => {
  return emissions.filter((emission) => !emission.archiveSender);
};

type EmissionParams = {
  companyID: string;
  approveUserID: string;
  title: string;
  manageNo: string;
  transactionedAt: string;
  note: string;
  activities: { activityID: string; volume: number }[];
};

const updateExec = async (action: string, payload: any, id?: string) => {
  const result: { data: any } = await callable(
    await functionsPayload(action, payload, id)
  );

  if ("error" in result.data) return result.data as MyError;

  const response = result.data as { item: EmissionResponse };
  return {
    item: EmissionFactory.createFromResponseObject(response.item),
  };
};

export class EmissionRepository {
  static async index(
    receives?: boolean,
    archive?: boolean,
    page?: number,
    limit?: number
  ): Promise<{ items: Emission[]; pages: Pages } | MyError> {
    const result: { data: any } = await callable(
      await functionsPayload("index", { receives, archive, page, limit })
    );
    if ("error" in result.data) return result.data as MyError;

    const response = result.data as EmissionResponses | MyError["error"];
    if (!response) return err();

    if ("status" in response) return { error: response };

    if ("items" in response) {
      const { items: activities, pages } = response;

      const items = activities.map((value) => {
        return EmissionFactory.createFromResponseObject(value);
      });

      return { items, pages };
    }

    return err();
  }
  static async show(id: string): Promise<{ item: Emission } | MyError> {
    const result: { data: any } = await callable(
      await functionsPayload("show", {}, id)
    );
    if ("error" in result.data) return result.data as MyError;

    const response = result.data as { item: EmissionResponse };
    return {
      item: EmissionFactory.createFromResponseObject(response.item),
    };
  }
  static async create(
    params: EmissionParams
  ): Promise<{ item: Emission } | MyError> {
    const result: { data: any } = await callable(
      await functionsPayload("create", params)
    );
    if ("error" in result.data) return result.data as MyError;

    const response = result.data as { item: EmissionResponse };
    return {
      item: EmissionFactory.createFromResponseObject(response.item),
    };
  }
  static async update(
    id: string,
    params: EmissionParams
  ): Promise<{ item: Emission } | MyError> {
    return updateExec("update", params, id);
  }
  static async updateStatus(
    id: string,
    status: number
  ): Promise<{ item: Emission } | MyError> {
    return updateExec("update", { status }, id);
  }

  static async updateCategory(
    id: string,
    activityIndex: number,
    categoryID: string
  ): Promise<{ item: Emission } | MyError> {
    const payload = { activityIndex, categoryID };
    return updateExec("updateCategory", payload, id);
  }

  static async notify(id: string): Promise<{ item: Emission } | MyError> {
    return updateExec("notify", {}, id);
  }

  static async confirm(id: string): Promise<{ item: Emission } | MyError> {
    return updateExec("confirm", {}, id);
  }

  static async archiveSender(
    id: string,
    archive: boolean
  ): Promise<{ item: Emission } | MyError> {
    return updateExec("archiveSender", { archive }, id);
  }

  static async archiveReceiver(
    id: string,
    archive: boolean
  ): Promise<{ item: Emission } | MyError> {
    return updateExec("archiveReceiver", { archive }, id);
  }

  static async notificationTargets(): Promise<
    { items: { id: string; name: string; category: string }[] } | MyError
  > {
    const result: { data: any } = await callable(
      await functionsPayload("notificationTargets", {})
    );
    if ("error" in result.data) return result.data as MyError;

    const response = result.data as
      | NotificationTargetResponses
      | MyError["error"];
    if (!response) return err();

    if ("status" in response) return { error: response };

    if ("items" in response) {
      const { items } = response as NotificationTargetResponses;
      return { items };
    }

    return err();
  }
}
