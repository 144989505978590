export const commas = (x: number | string): string => {
  if (!x) {
    return "";
  }
  const result = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const [integer, decimal] = result.split(".");
  if (decimal) return `${integer}.${decimal.replace(/,/g, "")}`; // 小数点以下のカンマは除去
  return result;
};

// {
//   codePrefix: string;
//   errorInfo: { code: string; message: string };
// }
export const fbAuthError = (e: { code: string; message: string }): string => {
  const defaultMessage = "入力した情報に誤りがあります";
  switch (e.code) {
    case "auth/invalid-password":
      return "パスワードは6文字以上の文字列を指定する必要があります。";
    // 古い?
    case "auth/cancelled-popup-request":
    case "auth/popup-closed-by-user":
      return "";
    case "auth/email-already-in-use":
    case "auth/email-already-exists":
      return "このメールアドレスは使用されています";
    case "auth/invalid-email":
      return "メールアドレスの形式が正しくありません";
    case "auth/user-disabled":
      return "サービスの利用が停止されています";
    case "auth/user-not-found":
      return defaultMessage;
    case "auth/user-mismatch":
      return "認証されているユーザーと異なるアカウントが選択されました";
    case "auth/weak-password":
      return "パスワードは6文字以上にしてください";
    case "auth/wrong-password":
      return defaultMessage;
    case "auth/popup-blocked":
      return "認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください";
    case "auth/operation-not-supported-in-this-environment":
    case "auth/auth-domain-config-required":
    case "auth/operation-not-allowed":
    case "auth/unauthorized-domain":
      return "現在この認証方法はご利用頂けません";
    case "auth/requires-recent-login":
      return "認証の有効期限が切れています";
    default:
      return "認証に失敗しました。しばらく時間をおいて再度お試しください";
  }
};

export const toFixedAndToLocale = (val: number, digit: number = 3): string => {
  return val.toLocaleString("ja-JP", {
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  });
};
