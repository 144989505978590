import React, { FC } from "react";
import { Badge } from "react-bootstrap";

const EnvBadge: FC = () => {
  const name = process.env.NEXT_PUBLIC_MY_APP_NAME;
  if (!name || name === "PRD") return <></>;

  return (
    <h6 className="d-inline">
      <small>
        <Badge className="ms-2">{name}</Badge>
      </small>
    </h6>
  );
};

export default EnvBadge;
